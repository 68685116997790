import { styled } from '@mui/material/styles';

import PageMargins from '@/components/ui/PageMargins/PageMargins';

export const Wrapper = styled(PageMargins, {
  shouldForwardProp: prop =>
    !['hasPrevBentoSibling', 'hasNextBentoSibling'].includes(prop),
})(({ theme, hasPrevBentoSibling, hasNextBentoSibling }) => ({
  margin: theme.spacing(
    hasPrevBentoSibling ? 6.5 : 12.5,
    'auto',
    hasNextBentoSibling ? 6.5 : 12.5,
    'auto'
  ),
}));
