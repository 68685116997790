import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as MuiLink, Stack } from '@mui/material';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

const Link = ({ link, className }) => {
  return (
    <Stack alignItems="flex-start" className={className}>
      <MuiLink
        href={link.data.attributes.url}
        color="primary"
        component={NextLink}>
        <Stack direction="row" alignItems="center" spacing={1} flexGrow={0}>
          <span>{link.data.attributes.text}</span>
          <ArrowForwardIcon />
        </Stack>
      </MuiLink>
    </Stack>
  );
};

Link.propTypes = {
  link: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default Link;
