import { useEffect, useState } from 'react';

export const BENTO_GRID_ROW_CLASS_NAME = 'bento-grid-row';

export function useBentoGridRowSibling(wrapperRef) {
  const [hasPrevBentoSibling, setHasPrevBentoSibling] = useState(false);
  const [hasNextBentoSibling, setHasNextBentoSibling] = useState(false);

  useEffect(() => {
    if (wrapperRef.current) {
      const previousSibling = wrapperRef.current.previousElementSibling;
      const previousSiblingClassList = previousSibling?.className.split(' ');
      const previousSiblingBento = Boolean(
        previousSiblingClassList?.includes(BENTO_GRID_ROW_CLASS_NAME)
      );

      if (previousSiblingBento) setHasPrevBentoSibling(true);

      const nextSibling = wrapperRef.current.nextElementSibling;
      const nextSiblingClassList = nextSibling?.className.split(' ');
      const nextSiblingBento = Boolean(
        nextSiblingClassList?.includes(BENTO_GRID_ROW_CLASS_NAME)
      );

      if (nextSiblingBento) setHasNextBentoSibling(true);
    }
  }, []);

  return { hasPrevBentoSibling, hasNextBentoSibling };
}
